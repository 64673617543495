import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { v4 as uuid } from 'uuid';
import { format } from 'date-fns';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MetaData from '../form/metadata';
import cleanTypeName from '../../utils/cleanTypeName';
import getSubscriptionsByCustomerIdQuery from '../queries/getSubscriptionsByCustomerId';
import getPaymentMethods from '../queries/getPaymentMethods';
import getMerchantConfigQuery from '../queries/getMerchantConfig';
import createSubscriptionMutation from '../mutations/createSubscriptionMutation';
import CircleProgress from '../../../components/progress/circle';
import DatePicker from '../../../components/date-picker';
import AddPaymentMethodToSubscription from './add-payment-method-to-subscription';
import AddButton from '../../../components/buttons/add-button';
import AddNewProductToSubscription from './add-product-to-subscription';
import SearchInput from '../../../components/search-input';
import Table from '../../../components/table';
import Can from '../../../components/auth/userCanPerform';
import getPaymentMethodDetails from '../utils/get-payment-method-details-from-vault';
import './add-subscription.scss';

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    marginLeft: '10px',
  },
}));

const columnsToShow = [
  {
    key: 'merchantProductId',
    displayName: 'Product Id'
  },
  {
    key: 'sku',
    displayName: 'SKU'
  },
  {
    key: 'description',
    displayName: 'Description'
  },
  {
    key: 'frequency',
    displayName: 'Frequency'
  },
  {
    key: 'amount',
    displayName: 'Amount'
  }
];

const startDate = format(new Date(), 'MM/dd/yyyy');
const getInitalSubscriptionObject = () => ({
  id: uuid(),
  total: 0,
  start: startDate,
  end: undefined,
  fixed: false,
  products: [],
  paymentMethods: []
});

const AddSubscription = (props) => {
  const [initialFormValues, setIntialFormValues] = useState(false);
  const [selectedFromSearch, setSelectedFromSearch] = useState([]);
  const [products, setProducts] = useState([]);
  const [disableAddButton, setDisableAddButton] = useState(false);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [combinedData, setCombinedData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const classes = useStyles();

  const { loading: loadingConfig, data: config } = useQuery(getMerchantConfigQuery, {
    client: props.client
  });
  const { loading, data } = useQuery(getPaymentMethods, {
    variables: { input: { externalId: props.customerId } },
    client: props.client
  });
  const [createSubscription, { mutationLoading }] = useMutation(
    createSubscriptionMutation,
    {
      client: props.client,
      refetchQueries: [
        {
          query: getSubscriptionsByCustomerIdQuery,
          variables: { customerId: props.customerId }
        }
      ]
    }
  );
  const { auth } = props;

  useEffect(() => {
    if (data == null) return;

    const updatedPaymentMethods = [];
    const getPaymentMethodsResult = _.get(data, 'getPaymentMethods');
    const requests = getPaymentMethodsResult.map((item) => getPaymentMethodDetails(item.token));
    Promise.all(requests).then((responses) => {
      for (const response of responses) {
        updatedPaymentMethods.push(response);
      }
      const concatData = getPaymentMethodsResult.map(
        (item, i) => ({ ...item, ...updatedPaymentMethods[i] })
      );

      setCombinedData(concatData);
    });
  }, [data]);

  if (loading || loadingConfig || mutationLoading || !data) {
    return <CircleProgress size="1em" />;
  }

  const disableSaveButton = !initialFormValues
    || products.length === 0
    || selectedPaymentMethods.length === 0
    || isLoading;

  const handleResetSubscription = () => {
    setDisableAddButton(false);
    setSelectedFromSearch([]);
    setProducts([]);
    setSelectedPaymentMethods([]);
  };

  const handleMutation = async (dataObject) => {
    setLoading(true);
    try {
      const paymentMethodIds = selectedPaymentMethods.map((item) => item.id);
      selectedPaymentMethods.map((paymentMethod) => cleanTypeName(paymentMethod));

      const { customerId } = props;
      const total = products.reduce((acc, product) => acc + Number(product.amount), 0);
      /*
        todo: we can probably remove this, but for sake of time
        and less pain its here. the API requires merchantId at
        time of commit. so all new products would have this value
        and not require this hack.
      */
      const { merchantId, defaultMerchantId } = _.get(config, 'getMerchantConfig[0].acquirers[0]', {});
      const _products = products.map((item) => ({
        ...item,
        merchantId: merchantId || defaultMerchantId || 'not-set'
      }));

      const newDate = format(new Date(selectedStartDate), 'MM/dd/yyyy');

      const _metadata = cleanTypeName(dataObject.metadata);

      const subscriptionItem = {
        metadata: _metadata,
        start: newDate
          ? new Date(newDate).toISOString()
          : null,
        total,
        end: dataObject.end ? new Date(dataObject.end).toISOString() : null,
        products: _products,
        paymentMethodIds,
        merchantSubscriptionId: 'fix' // todo: implement field after fixing stg
      };
      const variables = {
        input: {
          ...subscriptionItem,
          customerId
        }
      };

      await createSubscription({ variables });
      handleResetSubscription();
      setLoading(false);
      window.scrollTo(0, 0); // Scroll up after validation
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };

  const handleSelectedPaymentMethod = (dataObject) => {
    _.unset(dataObject, 'displayCard');
    _.unset(dataObject, 'displayExpiration');
    // destructuring here eliminates nested JSON errors
    const { displayCard, displayExipiration, ...cardData } = dataObject;
    setSelectedPaymentMethods([cardData]);
  };

  const handleProductSelectedFromSearch = (product) => {
    // const exists = selectedFromSearch.find((item) => item.id === product.id);

    // if (!exists) {
    const update = [].concat(selectedFromSearch, [product]);
    setSelectedFromSearch(update);
    // }
  };

  const handleRemoveNewProduct = (productId) => {
    const update = selectedFromSearch.filter((item) => item.id !== productId);
    setSelectedFromSearch(update);
  };

  const handleAddNewProduct = async (product) => {
    console.log('add new prod', product);
    // const exists = products.find((item) => item.id === product.id);
    // if (!exists) {
    // const update = [].concat(products, [product]);
    const update = [].concat(products, product);
    setProducts(update);
    setSelectedFromSearch([]);
    // }
  };

  const handleSelectedStartDate = (dataObject) => {
    if (!dataObject || dataObject === 'Invalid Date') return;
    setSelectedStartDate(dataObject);
  };

  const { handleBlur } = props;

  return (
    <div className="add-subscription">
      <div className="add-subscription-header">
        <Typography variant="h3">Subscriptions</Typography>
        <Can
          groups={auth.groups}
          perform="subscription:add"
          yes={() => (
              <AddButton
                label="Add"
                onClick={() => {
                  setDisableAddButton(true);
                  setIntialFormValues(getInitalSubscriptionObject());
                }}
              />

          )}
        />
      </div>
      {disableAddButton ? (
        <Formik
          initialValues={initialFormValues}
          onSubmit={handleMutation}
          render={({
            values,
            errors,
            handleChange,
            handleSubmit,
            isSubmitting
          // todo: having this form on the add customer page
          // causes an error.
          }) => (
              <form onSubmit={handleSubmit}>
                <div className="subscription-row">
                  <DatePicker
                    selectedDate={selectedStartDate}
                    handleChange={handleSelectedStartDate}
                    label="Start Date"
                  />
                </div>
                <div className="subscription-row">
                  <SearchInput
                    type="products"
                    onSelected={handleProductSelectedFromSearch}
                    // filterList={products}
                    // filter list taken out to enable adding multiple products to subscription
                  />
                </div>
                <div className="subscription-row add-new">
                  {selectedFromSearch.map((item) => (
                      <AddNewProductToSubscription
                        key={item.id}
                        product={item}
                        onClick={handleAddNewProduct}
                        onRemove={handleRemoveNewProduct}
                      />
                  ))}
                </div>
                <div className="subscription-row">
                  <Table columnsToShow={columnsToShow} data={products} />
                </div>
                <div className="subscription-row add-payment">
                  <AddPaymentMethodToSubscription
                    data={combinedData}
                    onSelect={handleSelectedPaymentMethod}
                    selectedPaymentMethods={selectedPaymentMethods}
                  />
                </div>
                <Grid>
                  <MetaData
                    data={values.metadata}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors}
                  />
                </Grid>
                <div className="subscription-row">
                  <Button
                    disabled={disableSaveButton}
                    label="Add Subscription"
                    variant="contained"
                    color="primary"
                    onClick={() => handleMutation(values)}
                  >
                    Add Subscription
                    </Button>
                  <Button
                    className={classes.cancelButton}
                    label="cancel"
                    variant="outlined"
                    onClick={handleResetSubscription}
                    id="cancel-sub"
                  >
                    Cancel
                  </Button>
                </div>
              </form>
          )
          }
        />
      ) : null}
    </div>
  );
};

export default AddSubscription;
